.modal {
  align-items: center;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
}

.modal.show {
  background-color: rgba(255, 255, 255, .50);
  pointer-events: visible;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 7px #03537a;
  display: flex;
  flex-direction: column;
  height: 50%;
  transform: translateY(-200px);
  transition: all 0.5s ease-in-out;
  width: 500px;
}

.modal.show .modal-content {
  transform: translateY(0);
}

.modal-header, .modal-footer {
  padding: 10px;
  background-color: #ffffff;
  display: flex;
}
.modal-content :first-child {
  border-radius: 10px 10px 0 0;
}

.modal-content :last-child {
  border-radius: 0 0 10px 10px;
}

.modal-title {
  margin: 0 auto 0 auto;
  text-align: center;
}

.modal-body {
  padding: 10px;
  border-top: 1px solid #03537a;
  border-bottom: 1px solid #03537a;
  overflow: scroll;
}