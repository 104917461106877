/* $darkBlue: #02547A;  */
/* $lightGray: #F3F3F3;  */

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p.headerWithShadow {
  font-family: 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 52px;
  font-weight: 300;
}

p.subHeader {
  color: white;
  font-size: 20px;
  font-weight: normal;
}

p.mediumParagraph {
  color: #828282;
  font-size: 17px;
  font-weight: 400;
  text-align: left;
}

div.divFlipHoverRightCorner:hover {
  border-color: yellow !important;
}

div.divFlipHoverRightCorner:hover div {
  border-left-color:  yellow !important;
  border-bottom-color:  yellow !important;
}

div.divFlipHoverLeftCorner:hover {
  border-color: yellow !important;
}

div.divFlipHoverLeftCorner:hover div {
  border-right-color:  yellow !important;
  border-bottom-color:  yellow !important;
}

p.cardHeader{

  color: #333;
  font-size: 18px;
  font-weight: normal;
}

p.cardSubHeader {

  color: #333;
  font-size: 14px;
  font-weight: normal;
}

ul.footerLinks li a {
  color: white;
  text-decoration: none;
}

ul.footerLinks  {
  padding-left: 15px;
}

.typeAheadDivContainerWrapper {
  z-index: 2000;
  position: absolute;
  top: 42px;
  left: 0px;
}

.typeAheadDivContainer {
  height: 150px;
  width: 200px;
  overflow-Y: auto;
  background-color: #f3d9a6;
  border: solid 2px #02547A;
  padding-top: 5;
  padding-bottom: 5;
  text-align: left;
}

.typeAheadDiv {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border: solid 2px #f3d9a6;
}

.typeAheadDiv:hover {
  border: solid 2px #02547A;
}

.typeAheadDiv.active {
  background-color: #02547A;
  color: white;
}

.geneSearchTable {
  border-collapse: collapse;
}

.geneSearchTable tbody tr td {
  border: solid 0px red;
}

.loaderIcon, .spin.FaIcons {
  margin-right: 0px;
  top: 0px;
  animation: spin 4s infinite linear;
}

@keyframes spin {
  0% {
    /*transform: scale(1) rotate(0deg);*/
    transform: scale(.75);
    color: rgb(223, 126, 35);
  }
  25% {
    color: green;
  }
  50% {
    /*transform: scale(1) rotate(360deg);*/
    transform: scale(1.25);
    color: green;
  }
  75% {
    color: green;
  }
  100% {
    /*transform: scale(1) rotate(0deg);*/
    transform: scale(.75);
    color: rgb(223, 126, 35);
  }
}

.divButtonHover {
  border: solid 3px #EFEFEF;
}
.divButtonHover:hover {
  border: solid 3px yellow !important;
}

.form-control:focus {
  border: solid 3px #ABABAB !important;
  outline: none !important;
}
