div.flipCardDiv {
  border: solid 0px red;
  position: relative;
  margin-bottom: 15px;
  margin-right: 20px;
  margin-left: 0px;
  position: relative;
  font-size: 17px;
  line-height: 1.25;
}

div.flipCardDiv b {
  font-size: 20px;
}

div.flipCardDiv .open b {
  font-size: 16px;
}

div.flipCardDiv div.open  div img {
  display: none;
}
div.flipCardDiv div.closed  div img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

div.flipCardDiv .closed {
  width: 285px;
  height: 190px;
  border: solid 2px #945232;
  border-radius: 10px;
  padding: 10px;
  padding-top: 15px;
  margin-left: 0px;
  background-color: #EAEAEA;
  /*animation: openClose 0.5s forwards linear;*/
}



div.flipCardDiv .open {
  width: 285px;
  height: 225px;
  border: solid 2px #333;
  border-radius: 10px;
  padding: 10px;
  padding-top: 15px;
  background-color: #F6F6F6;
  animation: openClose 0.5s forwards linear;
}

div.flipCardDiv .open:hover {
  border: solid 2px green;
  cursor: pointer;
}

@keyframes openClose {
  0% {
    transform: scale(1) rotateY(180deg);
  }
  100% {
    transform: scale(1) rotateY(0deg);
  }
}
